<template>
  <div class="content" @click="tohome()">
    <div @click.stop="toMIIT()">
      闽ICP备20012712号
    </div>
  </div>
</template>

<script>
  export default {
    methods:{
      tohome(){
        window.open("https://www.fzzgsc.com/2022","_self")
      },
      toMIIT(){
        window.open("https://beian.miit.gov.cn/#/Integrated/index","_blank")
      }
    }
  }
</script>

<style scoped>
  .content{
    width: 100%;
    height: 100vh;
    background: url('@/assets/bgimg.jpg') no-repeat scroll center ;
    background-size: 100vw 100vh;
  }
  .content>div{
    color: #fff;
    width: 200px;
    margin: auto;
    
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -100px;
    cursor: pointer;
  }
</style>